import Vue from 'vue'
import VueRouter from 'vue-router'

const Index = () => import('@/views/index')
const About = () => import('@/views/layout/about')
const kind = () => import('@/views/layout/kind')
const price = () => import('@/views/layout/price')
const index = () => import('@/views/layout/index')
const En = () => import('@/views/layout/environment')
const Serve = () => import('@/views/layout/serve')
const More = () => import('@/views/layout/more')
const Mzsm = () => import('@/views/layout/mzsm')
const Notice = () => import('@/views/layout/notice')
Vue.use(VueRouter)

const routes = [
  // 配置一级路由
  {
    path: '/',
    redirect: '/index',
    component: Index,
    // 配置二级路由
    children: [
      { path: '/environment', component: En },
      { path: '/serve', component: Serve },
      { path: '/about', component: About },
      { path: '/kind', component: kind },
      { path: '/price', component: price },
      { path: '/more', component: More },
      { path: '/index', component: index },
      { path: '/mzsm', component: Mzsm },
      { path: '/notice', component: Notice }]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
