import Vue from 'vue'

import {
  Button,
  Carousel,
  CarouselItem,
  Menu,
  MenuItem,
  Table,
  TableColumn,
  Backtop,
  Steps,
  Step,
  Icon
} from 'element-ui'

Vue.use(Button)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Backtop)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Icon)
