import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/utils/ele'
import '@/style/commen.less'
import '@/assets/iconfont/iconfont.css'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')

// 路由守卫，控制跳转后的置顶
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.innerText
    el.remove()
  }
})
